#skillset {
  color:white;
  text-align: left;
  /* margin-right: 5rem; */
}

#skillset .h1 {
  background-color: black;
  color: white;
  border-radius: 5px;
}

#skillbox {
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* flex-wrap: wrap; */
  /* -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row; */
  flex-direction: column;
}

#skillbox > ul {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 3 1fr;
      grid-template-columns: 3 1fr;
  justify-items: right;
}

#skillbox > label {
  color:white;
  font-size: 1.5rem;
  font-weight: bold;
}

ul {
  list-style: none;
  display: inline-block;
  text-align: left;
}

ul :nth-child(1) {
  font-weight: bold;
  font-size: larger;
}

.icon {
  height: 1rem;
  border-radius: 50%;
  color: black;
  background-color: white;
  border: 1px solid grey;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.blue {
  color: blue;
}

.green {
  color: green;
}

.tooltip .tooltip-content {
  visibility: hidden;
  position: absolute;
  background-color: rgba(0,0,0,0);
  color: whitesmoke;
  border: 1px solid grey;
  border-radius: 5%;
  padding: 1em;
  z-index: 1;
  font-size: 1.5rem;
  font-weight: 250;
  right: 100%;
  top: 25%;
}

.tooltip:hover .tooltip-content {
  visibility: visible;
}
