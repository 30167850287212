.App {
  /* text-align: center;
  width: 90%;
  margin: 0 auto; */
}

.main {
  margin: 0 auto;
  text-align: center;
  min-height: 90vh;
  max-width: 100vw;
  /* text-align: center; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  /* align-items: center; */
  /* justify-content: center; */
  /* font-size: calc(10px + 2vmin); */
  /* color: white; */
}

.nav {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #222;
  color: lightgrey;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  opacity: .5;
}