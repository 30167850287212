@import './crtEffect.css';

:root {
  --clr-glow: rgba(50, 255, 0, 0.1);
  --clr-text: rgb(37, 224, 0);
  --clr-screen-bg: #0007;
  /* --clr-text: whitesmoke;
  --clr-screen-bg: rgb(0, 15, 219); */
  /* --clr-screen-border: #777; */
  --clr-screen-border: rgba(255,255,255,.125);
}

body {
  background-color: black;
}

@-webkit-keyframes blink-caret {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}

@keyframes blink-caret {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    background-color: rgba(0, 0, 0, 0);
    background-color: transparent;
  }
  50% {
    background-color: #777;
  }
}

@keyframes blink {
  from,
  to {
    background-color: rgba(0, 0, 0, 0);
    background-color: transparent;
  }
  50% {
    background-color: #777;
  }
}

.console {
  margin: 0 auto;
  /* border: .5em var(--clr-screen-border) solid; */
  width:min-content;
  max-width: 100%;
  height:min-content;
  position: relative;
  border-radius: 1em;
  /* min-width: 20em;
  width:auto;
  min-height:15em;
  max-height: 75vh; */
  /* background-color: rgba(0, 0, 255, 0.75); */
  background-color: rgba(255,255,255,.125);
  transform-style: preserve-3d;
  animation: spin 10s infinite linear;
}

#input {
  font-size: 1rem;
  padding: 1em;
  color: var(--clr-text);
  font-family: 'Lucida Console';
  font-weight: 1000;
  border-radius: .5em;
  outline: 0px solid transparent;
  /* background-color: #222; */
  background-color: var(--clr-screen-bg);
  border: none;
  resize: none;
  width: auto;
  height:100%;
  box-shadow:
    0 0 .5em var(--clr-glow),
    inset 0 0 .5em var(--clr-glow);
} 

#caret {
  height: 1.5rem; 
  width: .75rem;
  content: '█';
  color:white;
  /* background-color: white; */
  -webkit-animation: 1s blink step-end infinite;
          animation: 1s blink step-end infinite;
}

.console-container {
  /* display: inline-block;
  text-align: left; */
  perspective: 40em;
}